<template>
  <div class="p-16 px-16 py-24 mine bg-color">
    <Navbar title="个人中心" :left-arrow="false" />
    <div class="blue-bg"></div>
    <div class="flex px-16 py-24 mine-card bda-4">
      <div class="flex flex-col justify-around flex-1 ml-16">
        <a class="font-18" href="/autonym/detail">
          {{ userStore.realName }}
        </a>
        <div v-hideTel="userInfoRef.driverPhone"></div>
      </div>
      <div class="flex flex-col pt-6">
        <div class="flex">
          <Icon :name="`rz${userInfoRef.driverId ? '' : '-in'}`" />
          <div class="ml-4 font-12 text_secondary">{{ userStore.realNameAuthStatus === 1 ? '已' : '未' }}实名</div>
        </div>
        <div
          class="switch-identity"
          @click="swithcIdentity(userIdentityRef ? IdentityEnum.CARRIER : IdentityEnum.DRIVER)"
        >
          切换为{{ userIdentityRef ? '车队长' : '司机' }}身份
        </div>
      </div>
    </div>

    <van-cell-group class="mt-16">
      <van-cell
        :title="item.name"
        v-for="(item, index) in tabsEnumSet1"
        :key="index"
        @click="() => handleJump(item.url)"
        is-link
        size="large"
      >
        <template #icon>
          <Icon :name="item.icon" />
        </template>
      </van-cell>
      <van-cell title="我的银行卡" @click="() => handleJump('bankcard')" is-link size="large">
        <template #icon>
          <Icon name="wdyhk" />
        </template>
        <template>
          <span class="text_primary">{{ userInfoRef.bankCardNum || '添加收款卡' }}</span>
        </template>
      </van-cell>
    </van-cell-group>

    <van-cell-group class="mt-16" v-show="userIdentityRef">
      <van-cell title="司机认证" @click="() => handleJump('driver')" is-link size="large">
        <template #icon>
          <Icon name="sjrz" />
        </template>
        <template>
          <span class="text_primary">{{ userInfoRef.driverId ? '已认证' : '未认证' }}</span>
        </template>
      </van-cell>
      <van-cell title="我的车辆" @click="() => handleJump('car')" is-link size="large">
        <template #icon>
          <Icon name="wdcl" />
        </template>
        <template>
          <span class="text_primary">{{ userInfoRef.truckNum ? '已认证' : '未认证' }}</span>
        </template>
      </van-cell>
      <van-cell title="我的车队" @click="() => handleJump('team')" is-link size="large">
        <template #icon>
          <Icon name="wodechedui" />
        </template>
      </van-cell>
      <van-cell title="我的合同" @click="() => handleJump('contract')" is-link size="large">
        <template #icon>
          <Icon name="wdht" />
        </template>
        <template>
          <span class="text_primary">立即签约</span>
        </template>
      </van-cell>
      <div class="my-12 line"></div>
    </van-cell-group>
    <van-cell-group class="mt-16" v-show="!userIdentityRef">
      <van-cell title="我的车队" @click="() => handleJump('team')" is-link size="large">
        <template #icon>
          <Icon name="wodechedui" />
        </template>
      </van-cell>
      <van-cell title="我的合同" @click="() => handleJump('contract')" is-link size="large">
        <template #icon>
          <Icon name="wdht" />
        </template>
        <template>
          <span class="text_primary">立即签约</span>
        </template>
      </van-cell>
    </van-cell-group>
    <van-cell-group class="mt-16">
      <van-cell :title="item.name" v-for="(item, index) in tabsEnumSet" :key="index" is-link size="large">
        <template #icon>
          <Icon :name="item.icon" />
        </template>
      </van-cell>
    </van-cell-group>
  </div>
</template>
<script>
import { computed, defineComponent } from '@vue/composition-api';
import { tabsEnumSet, tabsEnumSet1 } from './constants/index';
import { useRouter } from '@/utils/compsitionHack';
import { Cell, CellGroup } from 'vant';
import { useUserStore } from '@/store/user';
import { IdentityEnum } from '@/enums/identityEnum';
import { PageEnum } from '@/enums/pageEnum';

export default defineComponent({
  name: 'Mine',
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup
  },
  setup() {
    const userStore = useUserStore();
    const userInfoRef = computed(() => userStore.userInfo);
    const userIdentityRef = computed(() => userStore.isDriver);

    const router = useRouter();
    const handleJumpList = {
      driver: () => {
        // console.log(userInfoRef?.value?.driverAuthVO ? PageEnum.BASE_DRIVER_AUTH_RESULT : PageEnum.BASE_DRIVER_AUTH);
        router.push(userInfoRef?.value?.driverAuthVO ? PageEnum.BASE_DRIVER_AUTH_RESULT : PageEnum.BASE_DRIVER_AUTH);
      },
      car: () => {
        if (!userInfoRef.value?.driverAuthVO) {
          // 修改逻辑: 未发起审核过时才不能进入车辆添加
          router.push(PageEnum.BASE_DRIVER_AUTH);
        } else {
          router.push(userInfoRef.value.truckNum ? PageEnum.BASE_CAR_LIST : PageEnum.BASE_CAR_ADD);
        }
      },
      team: () => {
        // 我的车队
        router.push(userIdentityRef.value ? PageEnum.DRIVER_FLEET : PageEnum.CARRIER_FLEET);
      },
      contract: () => {
        if (!userInfoRef.value.driverId) {
          // 未司机实名认证
          router.push(PageEnum.BASE_DRIVER_AUTH);
        } else {
          router.push({
            path: PageEnum.CONTRACT_LIST,
            query: {
              driverId: userInfoRef.value.driverId
            }
          });
        }
      },
      bankcard: () => {
        router.push(PageEnum.BASE_BANK);
      }
    };
    const handleJump = item => {
      handleJumpList[item] ? handleJumpList[item]() : router.push(item);
    };

    const swithcIdentity = item => {
      // 切换用户身份并设置
      userStore.setUserIdentity(item);
    };

    return {
      userInfoRef,
      userStore,
      userIdentityRef,
      tabsEnumSet,
      tabsEnumSet1,
      IdentityEnum,
      handleJump,
      swithcIdentity
    };
  }
});
</script>
<style lang="less">
.mine {
  height: 100%;
  position: relative;
  span {
    text-align: center;
    padding: 24px;
    margin: 0;
    color: #d1d1d1;
    font-weight: bold;
  }
  .blue-bg {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    height: 1.35rem;
    width: 100%;
    background: linear-gradient(145deg, #74c9ff 0%, #0076ff 85%);
    border-radius: 0px 0px 16px 16px;
  }
  .mine-card {
    position: relative; // 覆盖上面的背景
    height: 1rem;
    z-index: 1;
    width: 100%;
    background-color: white;
    img {
      width: 0.52rem;
      height: 0.52rem;
      border-radius: 50%;
    }
  }
}
</style>
